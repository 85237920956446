
import { defineComponent, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { ITrainingItem } from '@/store/training/types'
import hhRequest from '@/service'
import TrainingLearnContent from './cpns/training-learn-content.vue'

export default defineComponent({
  components: {
    TrainingLearnContent
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const collapse = ref(false)
    const open = ref(false)

    const {
      params: { urlKey },
      query: { cid = 1, sid = 1 }
    } = route

    // 请求网络数据
    store.dispatch('training/getTrainingDetailDataAction', {
      url: `/training/${urlKey}`
    })
    store.dispatch('training/getTrainingContentDataAction', {
      url: `/training/${urlKey}/content`
    })

    // 从vuex中获取数据
    const training = computed(() => store.getters['training/getTrainingDetail'])
    const contents = computed(
      () => store.getters['training/getTrainingContent']
    )

    const page = computed(() => {
      const {
        query: { cid = 1, sid = 1 }
      } = route
      const item = contents.value[Number(cid) - 1]
      console.log('hh=============content.item', item)
      if (item.type === 'project') {
        return item['pages'][Number(sid) - 1]
      } else {
        return item
      }
    })

    const onSelect = (index: string) => {
      open.value = false
      const [cid, sid] = index.split('.')
      router.push({ query: { cid, sid } })
    }

    const toggle = () => {
      collapse.value = !collapse.value
    }

    // 计时器学习
    const recordStudyTime = () => {
      hhRequest.post<ITrainingItem>({
        url: `/training/${urlKey}/study`
      })
      setTimeout(recordStudyTime, 1000 * 60)
    }
    recordStudyTime()

    return {
      cid,
      sid,
      page,
      training,
      contents,
      onSelect,
      collapse,
      open,
      toggle
    }
  }
})
