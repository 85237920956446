
import { defineComponent, PropType, provide } from 'vue'
import { ITrainingItem, TrainingPage } from '@/store/training/types'
import MarkdownIt from '@jiker/markdown-it/MarkdownIt.vue'
import '@jiker/markdown-it/styles/markdown-it.css'
import Homework from './training-learn/homework.vue'
import Certificate from './training-learn/certificate.vue'
import Quiz from './training-learn/quiz.vue'

export default defineComponent({
  props: {
    training: {
      type: Object as PropType<ITrainingItem>,
      required: true
    },
    chapterIndex: {
      type: Number as PropType<any>,
      required: true
    },
    sectionIndex: {
      type: Number as PropType<any>,
      required: true
    },
    page: {
      type: Object as PropType<TrainingPage>,
      required: true
    }
  },
  components: {
    MarkdownIt,
    Homework,
    Certificate,
    Quiz
  },
  setup(props) {
    provide('training', props.training)
    provide('chapterIndex', props.chapterIndex)
    provide('sectionIndex', props.sectionIndex)
    return {}
  }
})
