import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3a75d163")
const _hoisted_1 = { class: "platform-content-mainer" }
const _hoisted_2 = { class: "markdown-container" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_markdown_it = _resolveComponent("markdown-it")!
  const _component_homework = _resolveComponent("homework")!
  const _component_quiz = _resolveComponent("quiz")!
  const _component_certificate = _resolveComponent("certificate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.page.content, (content, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: content }, [
        (content.type === 'text')
          ? (_openBlock(), _createElementBlock("section", {
              key: `content-${index}`
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_markdown_it, {
                  md: content.content
                }, null, 8, ["md"])
              ])
            ]))
          : (content.type === 'homework')
            ? (_openBlock(), _createElementBlock("section", {
                key: `content-${index}`
              }, [
                _createVNode(_component_homework)
              ]))
            : (
          content.type === 'quiz' &&
          (content.content.multipleChoiceQuestions ||
            content.content.trueOrFalseQuestions)
        )
              ? (_openBlock(), _createElementBlock("section", {
                  key: `content-${index}`
                }, [
                  _createVNode(_component_quiz, {
                    quiz: content.content
                  }, null, 8, ["quiz"])
                ]))
              : (content.type === 'certificate_apply')
                ? (_openBlock(), _createElementBlock("section", {
                    key: `content-${index}`
                  }, [
                    _createVNode(_component_certificate)
                  ]))
                : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}