
import { defineComponent, inject, PropType, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { TrainingContentQuiz, ITrainingItem } from '@/store/training/types'
import hhRequest from '@/service'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Quiz',
  props: {
    quiz: {
      type: Object as PropType<TrainingContentQuiz['content']>,
      required: true
    }
  },
  setup(props) {
    const training = inject<ITrainingItem>('training')!

    const multipleChoiceIndex = ref(0)
    const trueOrFalseIndex = ref(0)
    const route = useRoute()

    const {
      params: { urlKey },
      query: { cid = 1, sid = 1 }
    } = route

    const userAnswer = reactive<{
      answer: {
        multipleChoice: (string[] | null)[]
        trueOrFalse: (boolean | null)[]
      }
      submitted: boolean
    }>({
      answer: {
        multipleChoice: [],
        trueOrFalse: []
      },
      submitted: false
    })

    const selectMultipleChoice = (index: number, value: string) => {
      if (userAnswer.submitted) {
        return
      }
      let choice = userAnswer.answer.multipleChoice[index]
      if (!choice) {
        choice = userAnswer.answer.multipleChoice[index] = []
      }

      const key = choice.indexOf(value)
      if (key > -1) {
        choice.splice(key, 1)
      } else {
        choice.push(value)
      }

      if (choice.length === 0) {
        userAnswer.answer.multipleChoice[index] = null
      }
    }

    const selectTrueOfFalse = (index: number, value: boolean) => {
      if (userAnswer.submitted) {
        return
      }
      const select = userAnswer.answer.trueOrFalse[index]

      userAnswer.answer.trueOrFalse[index] = select === value ? null : value
    }

    // const { get, post, loading } = useRequest()
    const submit = async () => {
      console.log('hhhhhhhhhhhh')

      if (
        userAnswer.answer.multipleChoice.filter((v) => v).length <
          props.quiz.multipleChoiceQuestions?.length ||
        userAnswer.answer.trueOrFalse.filter((v) => v !== null).length <
          props.quiz.trueOrFalseQuestions?.length
      ) {
        ElMessage.warning({
          message:
            window.location.host === 'ru.iioe.org'
              ? 'Подтвердите ваши ответы'
              : 'Por favor, confirme su respuesta',
          type: 'warning'
        })
        return
      }

      try {
        await hhRequest.post({
          url: `/training/${training.urlKey}/answer`,
          data: {
            chapterIndex: cid,
            sectionIndex: sid,
            answer: userAnswer.answer
          }
        })

        userAnswer.submitted = true
      } catch (error) {
        return error
      }
    }

    const getUserAnswer = async () => {
      try {
        userAnswer.answer = await hhRequest.get({
          url: `/training/${training.urlKey}/answer`,
          params: {
            chapterIndex: cid,
            sectionIndex: sid
          }
        })
        userAnswer.submitted = true
      } catch (error) {
        return error
      }
    }
    getUserAnswer()

    return {
      userAnswer,
      multipleChoiceIndex,
      selectMultipleChoice,
      trueOrFalseIndex,
      selectTrueOfFalse,
      submit
    }
  }
})
