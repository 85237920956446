
import { defineComponent, inject, reactive, ref } from 'vue'
import { ElMessageBox } from 'element-plus'
import { ITrainingItem } from '@/store/training/types'
import hhRequest from '@/service'
import { useRoute } from 'vue-router'

export default defineComponent({
  // name: 'Homework',
  setup() {
    const training = inject<ITrainingItem>('training')!

    const homework = reactive({
      content: ''
    })
    const rules = {
      content: { required: true, message: 'Не должно быть пустым' }
    }
    const form = ref<any>(null)
    const submitted = ref(false)
    const submit = async () => {
      if (form.value) {
        try {
          await form.value.validate()

          await ElMessageBox.confirm(
            'Вы уверены, что готовы отправить задание?',
            {
              confirmButtonText: 'Подтвердите',
              cancelButtonText: 'Удалить'
            }
          )

          await hhRequest.post({
            url: `/training/${training.urlKey}/homework`,
            data: {
              chapterIndex: cid,
              sectionIndex: sid,
              content: homework.content
            }
          })
          submitted.value = true
        } catch (error) {
          return error
        }
      }
    }

    const route = useRoute()

    const {
      params: { urlKey },
      query: { cid = 1, sid = 1 }
    } = route
    const getHomework = async () => {
      try {
        await hhRequest.get({
          url: `/training/${training.urlKey}/homework`,
          params: {
            chapterIndex: cid,
            sectionIndex: sid
          }
        })
        submitted.value = true
      } catch {
        submitted.value = false
      }
    }
    getHomework()

    return {
      submitted,
      form,
      rules,
      homework,
      submit
    }
  }
})
