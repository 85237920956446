
import { defineComponent, inject, reactive, ref, toRaw, computed } from 'vue'
import { ITrainingItem } from '@/store/training/types'
import hhRequest from '@/service'
import { useStore } from '@/store'

export default defineComponent({
  // name: 'Certificate',
  setup() {
    const training = inject<ITrainingItem>('training')!
    const applying = ref(false)
    const editing = ref(false)
    const confirm = ref(false)

    const store = useStore()

    // 网络请求数据
    store.dispatch('training/trainingCertificateAction', {
      url: `/training/${training.urlKey}/apply`
    })

    const data: any = computed(
      () => store.getters['training/getTrainingCertificateData']
    )
    const state = reactive(data)

    const edit = async () => {
      await hhRequest.put({
        url: `/training/${training.urlKey}/apply`,
        data: toRaw(state)
      })
      editing.value = false
    }

    const submit = async () => {
      await hhRequest.post({
        url: `/training/${training.urlKey}/certificate`,
        data: { name: state.value.name }
      })
      applying.value = false
      confirm.value = true
    }

    return {
      state,
      editing,
      edit,
      submit,
      applying,
      confirm
    }
  }
})
